<template>
  <CRow>
    <CCol col="12">
      <CCard>
        <CCardHeader>
          <CIcon name="cil-user"/>
          <h5 class="d-inline ml-2">{{ this.first_name }}</h5>
          <div class="card-header-actions">
            <CButton color="dark" :to="this.prevRoute">
              <CIcon name="cil-arrow-thick-left" class="align-bottom" /> Back
            </CButton>
          </div>
        </CCardHeader>

        <CCardBody v-if="loading || !user">
          <CSpinner color="primary" />
        </CCardBody>

        <CCardBody v-else>
          <CMedia
            :aside-image-props="{
              width: 128,
              height: 128,
              src: user.image_icon || user.gravatar,
              shape: 'rounded-circle',
            }"
          >
            <CRow class="ml-2">
              <!-- Orders -->
              <CCol class="widget" col="12" sm="6" lg="4">
                <CWidgetIcon
                  header="Orders"
                  :text= String(user.orders_count)
                  color="gradient-dark"
                  :icon-padding="false"
                >
                  <CIcon name="cil-dinner" class="" width="24" />
                  <template #footer>
                    <CCardFooter class="px-3 py-2">
                      <CLink
                        v-if="typeof user.id !== 'undefined'"
                        :to="{name: 'UserOrderList', params: { user_id: user.id }}"
                        class="font-sm text-muted d-flex justify-content-between"
                      >
                        View Orders
                        <CIcon name="cil-arrow-right" width="16"/>
                      </CLink>
                    </CCardFooter>
                  </template>
                </CWidgetIcon>
              </CCol>

              <!-- Reviews -->
              <CCol class="widget" col="12" sm="6" lg="4">
                <CWidgetIcon
                  header="Reviews"
                  :text= String(user.reviews_count)
                  color="gradient-success"
                  :icon-padding="false"
                >
                  <CIcon name="cil-star" class="" width="24" />
                  <template #footer>
                    <CCardFooter class="px-3 py-2">
                      <CLink
                        v-if="typeof user.id !== 'undefined'"
                        :to="{ name: 'UserReviews', params: { user_id: user.id } }"
                        class="font-sm text-muted d-flex justify-content-between"
                      >
                        View Reviews
                        <CIcon name="cil-arrow-right" width="16"/>
                      </CLink>
                    </CCardFooter>
                  </template>
                </CWidgetIcon>
              </CCol>

              <!-- Addresses -->
              <CCol class="widget" col="12" sm="6" lg="4">
                <CWidgetIcon
                  header="Addresses"
                  :text= String(user.addresses.length)
                  color="gradient-warning"
                  :icon-padding="false"
                >
                  <CIcon name="cil-location-pin" class="" width="24" />
                  <template #footer>
                    <CCardFooter class="px-3 py-2">
                      <a v-smooth-scroll href="#user-addresses" class="font-sm text-muted d-flex justify-content-between">
                        View Addresses
                        <CIcon name="cil-arrow-right" width="16"/>
                      </a>
                    </CCardFooter>
                  </template>
                </CWidgetIcon>
              </CCol>
            </CRow>
          </CMedia>

          <h5 class="my-3">User Details</h5>

          <div class="d-inline-flex flex-wrap w-100">
            <div class="item-group">
              <label>Full Name:</label>
              <span>{{ user.first_name }}</span>
            </div>
            <div class="item-group">
              <label>ID#:</label>
              <span>{{ user.id }}</span>
            </div>

            <div class="item-group">
              <label>Language:</label>
              <CIcon v-if="user.lang == 'en'" class="flag" :height="14" :content="$options.flagSet['cifGb']" />
              <CIcon v-else class="flag" :height="14" :content="$options.flagSet['cif' + user.lang.charAt(0).toUpperCase() + user.lang.slice(1).toLowerCase()]" />
              <span class="ml-2">{{ user.lang.toUpperCase() }}</span>
            </div>
            <div class="item-group">
              <label>Country:</label>
              <template v-if="user.country">
                <CIcon class="flag" :height="14" :content="$options.flagSet['cif' + user.country.charAt(0).toUpperCase() + user.country.slice(1).toLowerCase()]" />
                <span class="ml-2">{{ user.country.toUpperCase() }}</span>
              </template>
              <span v-else>-</span>
            </div>

            <div class="item-group">
              <label>User Type:</label>
              <span>{{ user.usertype }}</span>
            </div>
            <div class="item-group">
              <label>Type:</label>
              <span>{{ user.type }}</span>
            </div>

            <div class="item-group">
              <label>Mobile:</label>
              <span>{{ user.mobile }}</span>
            </div>
            <div class="item-group">
              <label>2FA Mobile:</label>
              <span>{{ user.tfamobile }}</span>
            </div>
            <div class="item-group">
              <label>Gender:</label>
              <span class="gender">{{ user.gender == 1 ? "♂" : "♀" }}</span>
            </div>

            <div class="item-group">
              <label>Email:</label>
              <span>{{ user.email }}</span>
            </div>
            <div class="item-group">
              <label>Email Verified At:</label>
              <span>{{ user.email_verified_at }}</span>
            </div>

            <div class="item-group">
              <label>Email Subscribe:</label>
              <span>{{ user.email_subscribe == true ? "YES" : "NO" }}</span>
            </div>
            <div class="item-group">
              <label>Facebook ID#:</label>
              <span>{{ user.fb_id }}</span>
            </div>

            <div class="item-group">
              <label>Google ID#:</label>
              <span>{{ user.google_id }}</span>
            </div>
            <div class="item-group">
              <label>Apple ID#:</label>
              <span>{{ user.apple_id }}</span>
            </div>

            <div class="item-group">
              <label>IP:</label>
              <span>{{ user.ip }}</span>
            </div>
            <div class="item-group">
              <label>Last Login:</label>
              <span>{{ user.last_login }}</span>
            </div>

            <div class="item-group">
              <label>Created At:</label>
              <span>{{ user.created_at }}</span>
            </div>
            <div class="item-group">
              <label>Updated At:</label>
              <span>{{ user.updated_at }}</span>
            </div>

            <div class="item-group">
              <label>Address:</label>
              <span>{{ user.address }}</span>
            </div>
            <div class="item-group">
              <label>City:</label>
              <span>{{ user.city }}</span>
            </div>

            <div class="item-group">
              <label>Postal Code:</label>
              <span>{{ user.postal_code }}</span>
            </div>
          </div>


          <CCard id="user-addresses" class="mt-3">
            <CCardHeader>
              <CIcon name="cil-location-pin" class="align-bottom" />
              <strong> Addresses </strong>
              <small>({{ user.addresses.length }})</small>
            </CCardHeader>
            <CCardBody>
              <CListGroup accent>
                <CListGroupItem v-for="address of user.addresses" :key="address.id" accent="warning">
                  <strong>{{ address.maPostalcode }}</strong>,
                  {{ address.maCity }}<br />
                  {{ address.maAddress }}
                </CListGroupItem>
              </CListGroup>
            </CCardBody>
          </CCard>

        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import { flagSet } from '@coreui/icons'

export default {
  name: 'User',
  flagSet,

  data () {
    return {
      user: null,
      loading: true,

      // Get previous page
      prevRoute: null,
    }
  },

  // Get previous page
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from
    })
  },

  async created () {
    await this.$axios
      .get(this.$backend.USERS.VIEW.replace('{id}', this.itemId))
      .then((response) => this.user = response.data)
      .catch(() => this.$router.push({ name: 'All Users' }))
      .finally(() => this.loading = false)
  },

  computed: {
    itemId () { return this.$route.params.id },
    first_name () { return this.user ? this.user.first_name : '' }
  }
}
</script>

<style>
  .widget .card, .widget .card-footer {
    background-color: #f8f8ff;
  }
  .widget .text-value {
    font-size: 1rem;
  }
  .widget .text-muted {
    font-size: 1.5rem;
  }
</style>

<style scoped>
.item-group {
  width: 50%;
  display: inline-block;
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid;
  border-top-color: #d8dbe0;
}
.item-group:nth-child(4n+1), .item-group:nth-child(4n+2) {
  background-color: #f2f2f2;
}
.item-group label {
  font-weight: bolder;
  margin-bottom: 0;
  width: 10rem;
}
.item-group .gender {
  font-family: arial unicode ms,lucida sans unicode,sans-serif;
  font-size: 1rem;
  text-shadow: 1px 0 1px #ccc, 0 1px 1px #eee, 2px 1px 1px #ccc;
}
</style>
